import React from 'react';

interface FaceLivenessProps {
  videoRef: React.RefObject<HTMLVideoElement>;
  currentInstruction: string;
  attempts: number;
  totalAttempts: number;
  livenessDetected: boolean;
  flashEffect: boolean;
}
const FaceLivenessPresentation: React.FC<FaceLivenessProps> = ({
  videoRef,
  currentInstruction,
  attempts,
  totalAttempts,
  livenessDetected,
  flashEffect,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <h2>Prueba de Vida</h2>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        style={{ width: '30%', borderRadius: '10px' }}
      />
      <div
        style={{
          padding: '10px',
          background: livenessDetected ? '#4CAF50' : '#ffcc00',
          textAlign: 'center',
        }}
      >
        {attempts < totalAttempts ? (
          <>
            <h3>{currentInstruction}</h3>
            <p>
              Intentos: {attempts} / {totalAttempts}
            </p>
            {livenessDetected && <p>✔ Acción validada</p>}
          </>
        ) : (
          <h3>✅ Prueba de vida completada</h3>
        )}
      </div>
      {flashEffect && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'white',
            opacity: 0.7,
            zIndex: 1000,
          }}
        />
      )}
    </div>
  );
};

export default FaceLivenessPresentation;
