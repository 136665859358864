import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FaceMesh } from '@mediapipe/face_mesh';
import { Camera } from '@mediapipe/camera_utils';
import FaceLivenessPresentation from '../components/FaceLivenessPresentation';

interface FaceLivenessContainerProps {
  setLivenessDetected: Dispatch<SetStateAction<boolean>>;
  livenessDetected: boolean;
  setCapturedImage: Dispatch<SetStateAction<string | null>>;
}

const instructions = ['Parpadea', 'Mueve tu cabeza', 'Sonríe'];

const TOTAL_INTENTOS = 3;

const FaceLivenessContainer: React.FC<FaceLivenessContainerProps> = ({
  setLivenessDetected,
  livenessDetected,
  setCapturedImage,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const faceMeshRef = useRef<FaceMesh | null>(null);
  const currentInstructionRef = useRef<string[]>([]);
  const [currentInstructions, setCurrentInstructions] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [flashEffect, setFlashEffect] = useState(false);

  const captureImage = () => {
    if (!videoRef.current) return;
  
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    const ctx = canvas.getContext('2d');
  
    if (ctx) {
      ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const imageDataURL = canvas.toDataURL('image/jpeg');
      setCapturedImage(imageDataURL);
    }
  };
  
  useEffect(() => {
    if (!videoRef.current) {
      console.error('No se encontró el elemento videoRef');
      return;
    }

    console.log('Iniciando cámara...');
    const camera = new Camera(videoRef.current, {
      onFrame: async () => {
        if (faceMeshRef.current) {
          await faceMeshRef.current.send({ image: videoRef.current! });
        }
      },
      width: 640,
      height: 480,
    });

    camera.start();

    return () => {
      console.log('Deteniendo cámara...');
      camera.stop();
    };
  }, []);

  useEffect(() => {
    faceMeshRef.current = new FaceMesh({
      locateFile: (file) =>
        `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
    });

    faceMeshRef.current.setOptions({
      maxNumFaces: 1,
      refineLandmarks: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });

    faceMeshRef.current.onResults((results) => {
      if (
        !results.multiFaceLandmarks ||
        results.multiFaceLandmarks.length === 0
      ) {
        console.warn('No se detectaron landmarks en este frame.');
        return;
      }

      const landmarks = results.multiFaceLandmarks[0];

      const leftEyeTop = landmarks[159];
      const leftEyeBottom = landmarks[145];
      const rightEyeTop = landmarks[386];
      const rightEyeBottom = landmarks[374];

      const eyeThreshold = 0.02;
      const leftEyeDistance = Math.abs(leftEyeTop.y - leftEyeBottom.y);
      const rightEyeDistance = Math.abs(rightEyeTop.y - rightEyeBottom.y);
      const isBlinking =
        leftEyeDistance < eyeThreshold && rightEyeDistance < eyeThreshold;

      const nose = landmarks[1];
      const faceLeft = landmarks[234];
      const faceRight = landmarks[454];
      const isMovingHead =
        nose.x < faceLeft.x - 0.05 || nose.x > faceRight.x + 0.05;

      const mouthLeft = landmarks[61];
      const mouthRight = landmarks[291];
      const mouthDistance = Math.abs(mouthLeft.x - mouthRight.x);
      const isSmiling = mouthDistance > 0.1;

      const currentTask = currentInstructionRef.current[currentStep];

      if (currentTask === 'Parpadea' && isBlinking) {
        handleSuccess();
      } else if (currentTask === 'Mueve tu cabeza' && isMovingHead) {
        handleSuccess();
      } else if (currentTask === 'Sonríe' && isSmiling) {
        handleSuccess();
      }
    });

    return () => {
      console.log('Deteniendo FaceMesh...');
      faceMeshRef.current?.close();
    };
  }, [currentStep]);

  useEffect(() => {
    if (attempts >= TOTAL_INTENTOS) return;

    const newInstructions = [
      instructions[Math.floor(Math.random() * instructions.length)],
      instructions[Math.floor(Math.random() * instructions.length)],
    ];

    setCurrentInstructions(newInstructions);
    currentInstructionRef.current = newInstructions;
    setCurrentStep(0);
    setLivenessDetected(false);
  }, [attempts]);

  const playBeep = () => {
    const beep = new Audio('/beep-07a.wav');
    beep.play();
  };

  const triggerFlash = () => {
    setFlashEffect(true);
    setTimeout(() => setFlashEffect(false), 200);
  };

  const handleSuccess = () => {
    if (!livenessDetected) {
      console.log(
        `✔ Prueba de vida completada para: ${currentInstructionRef.current[currentStep]}`,
      );

      if (currentStep === 0) {
        setCurrentStep(1);
        setLivenessDetected(false);
      } else {
        setLivenessDetected(true);
        setAttempts((prev) => prev + 1);
        playBeep();
        triggerFlash();
        captureImage();
      }
    }
  };

  return (
    <FaceLivenessPresentation
      videoRef={videoRef}
      currentInstruction={currentInstructions[currentStep] || ''}
      attempts={attempts}
      totalAttempts={TOTAL_INTENTOS}
      livenessDetected={livenessDetected}
      flashEffect={flashEffect}
    />
  );
};

export default FaceLivenessContainer;
