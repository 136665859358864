import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import RegistrationUserFormPresentation from '../components/RegistrationUserFormPresentation';
import { makeRequest } from '../services/requests';
import constants from '../data/constants.json';
import {
  UserFrontend,
  WorkUserFrontend,
  UserBackendToSend,
} from '../services/BackendFrontendInterfaces';
import * as faceapi from 'face-api.js';

const RegistrationUserFormContainer: React.FC = () => {
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [livenessDetected, setLivenessDetected] = useState(false);
  const [picturesMatch, setPicturesMatch] = useState(false);
  const [cellPhones, setCellPhones] = useState<number[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState<UserFrontend>({
    token: '',
    curp: '',
    info: false,
    email: '',
    sex: true,
    rfc: '',
    linkCedule: '',
    identityValidated: false,
    rate_approved: 0,
    approved_by: 0,
    rate_disapproved: 0,
    disapproved_by: 0,
    rate_works_evaluated: 0,
    works_evaluated: 0,
    password: '',
    confirm_password: '',
    workerType: [],
    firstName: '',
    secondName: '',
    firstLastName: '',
    secondLastName: '',
    cellPhones: [],
    id_card: null,
    selfie: null,
  });

  const convertToJPG = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;
  
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
  
          const ctx = canvas.getContext("2d");
          if (!ctx) return reject("No se pudo obtener el contexto del canvas.");
  
          // Dibujar la imagen en el canvas y exportarla como JPG
          ctx.drawImage(img, 0, 0);
          resolve(canvas.toDataURL("image/jpeg", 0.9)); // 0.9 = calidad alta
        };
      };
  
      reader.onerror = () => reject("Error al leer el archivo.");
      reader.readAsDataURL(file);
    });
  };
  
  const validatePasswords = (): boolean => {
    if (formValues.password !== formValues.confirm_password) {
      alert('Las contraseñas no coinciden');
      return false;
    }
    return true;
  };

  const compareFaces = async () => {
    if (!capturedImage || !formValues.selfie) {
      console.log("🚨 Falta una de las imágenes para comparar.");
      return;
    }
  
    try {
      console.log("📸 Convirtiendo selfie PNG a JPG...");
      const selfieJPG = await convertToJPG(formValues.selfie);
      console.log("✅ Selfie convertida a JPG.");
  
      console.log("📸 Cargando imagen capturada...");
      const img1 = await faceapi.fetchImage(capturedImage);
      console.log("✅ Imagen capturada cargada.");
  
      console.log("📸 Cargando imagen selfie...");
      const img2 = await faceapi.fetchImage(selfieJPG);
      console.log("✅ Imagen selfie cargada.");
  
      console.log("🔍 Detectando rostros en imagen capturada...");
      const detections1 = await faceapi.detectSingleFace(img1, new faceapi.TinyFaceDetectorOptions({ scoreThreshold: 0.2 }))
        .withFaceLandmarks()
        .withFaceDescriptor();
  
      console.log("🔍 Detectando rostros en imagen selfie...");
      const detections2 = await faceapi.detectSingleFace(img2, new faceapi.TinyFaceDetectorOptions({ scoreThreshold: 0.2 }))
        .withFaceLandmarks()
        .withFaceDescriptor();
  
      if (!detections1) console.log("❌ No se detectó un rostro en la imagen capturada.");
      if (!detections2) console.log("❌ No se detectó un rostro en la imagen selfie.");
  
      if (!detections1 || !detections2) {
        console.log("⚠️ No se detectó un rostro en una de las imágenes. Cancelando comparación.");
        return;
      }
  
      console.log("🔢 Calculando distancia euclidiana...");
      const distance = faceapi.euclideanDistance(
        detections1.descriptor,
        detections2.descriptor
      );
  
      console.log(`📏 Distancia entre rostros: ${distance}`);
  
      if (distance < 0.6) {
        console.log("✅ Coincidencia encontrada, prueba de vida validada.");
        setPicturesMatch(true);
      } else {
        console.log("❌ Las imágenes no coinciden.");
      }
    } catch (error) {
      console.error("🚨 Error al comparar imágenes:", error);
    }
  };
  
  useEffect(() => {
    if (capturedImage && formValues.selfie) {
      compareFaces();
    }
  }, [capturedImage, formValues.selfie]);  
  

  const handleCellPhonesRegistrados = (cellPhonesList: number[]) => {
    setCellPhones(cellPhonesList);
  };

  useEffect(() => {
    setFormValues((prevValues) => ({ ...prevValues, cellPhones: cellPhones }));
  }, [cellPhones]);

  useEffect(() => {
    const loadModels = async () => {
      try {
        await faceapi.nets.tinyFaceDetector.loadFromUri('https://justadudewhohacks.github.io/face-api.js/models');
        await faceapi.nets.faceLandmark68Net.loadFromUri('https://justadudewhohacks.github.io/face-api.js/models');
        await faceapi.nets.faceRecognitionNet.loadFromUri('https://justadudewhohacks.github.io/face-api.js/models');
      } catch (error) {
        console.error("🚨 Error loading models:", error);
      }
    };
    loadModels();
    const FIRSTINVITEDUSERSTOKEN = process.env.REACT_APP_FIRSTINVITEDUSERSTOKEN;
    if (FIRSTINVITEDUSERSTOKEN) {
      setFormValues((prevValues) => ({
        ...prevValues,
        token: FIRSTINVITEDUSERSTOKEN,
      }));
    }
  }, []);
  
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: React.MouseEvent) =>
    event.preventDefault();

  const handleCheckboxWorkerTypeChange = (
    selection: WorkUserFrontend['workType'],
  ) => {
    let newArray: string[];
    if (
      formValues.workerType.includes(
        selection['Click Aquí Para Seleccionar Tipo De Trabajador'][0],
      )
    ) {
      newArray = [...formValues.workerType].filter(
        (element) =>
          element !==
          selection['Click Aquí Para Seleccionar Tipo De Trabajador'][0],
      );
    } else {
      newArray = [
        ...formValues.workerType,
        selection['Click Aquí Para Seleccionar Tipo De Trabajador'][0],
      ];
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      workerType: newArray,
    }));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    if (name.includes('id_card') || name.includes('selfie')) {
      if (event.target.files != null) {
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: event.target.files![0],
        }));
      }
    }
  };  

  const handleSelectChangeSexType = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const key = event.target.value === 'true' ? true : false;
    setFormValues((prevValues) => ({ ...prevValues, sex: key }));
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!validatePasswords()) {
      return;
    }

    try {
      const obj_to_send: UserBackendToSend = {
        email: formValues.email,
        sex: formValues.sex,
        link_cedule: formValues.linkCedule,
        worker_type: formValues.workerType.map((item) => ({
          type: item,
        })),
        first_name: { name: formValues.firstName },
        second_name: { name: formValues.secondName },
        first_lastname: { lastname: formValues.firstLastName },
        second_lastname: { lastname: formValues.secondLastName },
        cell_phones: formValues.cellPhones.map((item) => ({
          cell_phone: item,
          user_id: 0,
        })),
        curp: formValues.curp,
        rfc: formValues.rfc,
        password: formValues.password,
      };

      const path = `user/register_me/${formValues.token}`;
      const method = 'POST';

      const content_type = 'application/json';
      const response = await makeRequest({
        path: path,
        method: method,
        body: JSON.stringify(obj_to_send),
        contentType: content_type,
      });

      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }

      if (formValues.id_card && formValues.selfie) {
        const curp = obj_to_send.curp.toUpperCase();
        const password = obj_to_send.password;
        const path = `user/login`;
        const method = 'POST';
        const content_type = 'application/x-www-form-urlencoded';
        const body = new URLSearchParams({ curp, password });
        const responseLogin = await makeRequest({
          path: path,
          method: method,
          body: body,
          contentType: content_type,
        });

        if (!responseLogin.ok) {
          const response_json = await responseLogin.json();
          throw new Error(JSON.stringify(response_json.detail));
        }

        const header = {
          Accept: 'application/json',
        };
        const formData = new FormData();
        formData.append('id_card', formValues.id_card);
        formData.append('selfie', formValues.selfie);
        const response = await makeRequest({
          path: 'user/my_id',
          method: 'POST',
          body: formData,
          header: header,
        });
      }
      alert('Registro exitoso');
      window.location.reload();
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  return (
    <RegistrationUserFormPresentation
      formValues={formValues}
      showPassword={showPassword}
      handleInputChange={handleInputChange}
      handleSelectChangeSexType={handleSelectChangeSexType}
      handleCheckboxWorkerTypeChange={handleCheckboxWorkerTypeChange}
      handleClickShowPassword={handleClickShowPassword}
      handleMouseDownPassword={handleMouseDownPassword}
      handleSubmit={handleSubmit}
      handleCellPhonesRegistrados={handleCellPhonesRegistrados}
      setLivenessDetected={setLivenessDetected}
      livenessDetected={livenessDetected}
      picturesMatch={picturesMatch}
      setCapturedImage={setCapturedImage}
    />
  );
};

export default RegistrationUserFormContainer;
